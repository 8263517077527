<template>
  <div
    class="falling-guy"
    :style="{
      backgroundImage: `url(${currentFrame})`,
      top: computedTop,
    }"
  ></div>
</template>


<script>
export default {
  data() {
    return {
      scrollY: 0,
      jumpFrames: [],
      loopFallFrames: [],
      endFrames: [],
      maxWidth: 850, // Threshold for applying responsive behavior
      preloadedImages: [], // Store preloaded image elements
    };
  },
  computed: {
    currentFrame() {
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      let scrollFraction = this.scrollY / totalHeight;
      scrollFraction = Math.max(0, scrollFraction);

      if (scrollFraction <= 0.2) {
        const frameIndex = Math.floor(
          (scrollFraction / 0.2) * (this.jumpFrames.length - 1)
        );
        return this.jumpFrames[frameIndex];
      } else if (scrollFraction > 0.2 && scrollFraction <= 0.8) {
        const adjustedFraction = (scrollFraction - 0.2) / 0.8;
        const frameIndex =
          Math.floor(adjustedFraction * (this.loopFallFrames.length - 1) * 2) %
          this.loopFallFrames.length;
        return this.loopFallFrames[frameIndex];
      } else if (scrollFraction > 1) {
        return this.endFrames[this.endFrames.length - 1];
      } else {
        const adjustedFraction = (scrollFraction - 0.8) / 0.2;
        const frameIndex = Math.floor(
          adjustedFraction * (this.endFrames.length - 1)
        );
        return this.endFrames[frameIndex];
      }
    },
    computedTop() {
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const scrollFraction = this.scrollY / totalHeight;

      // Configuration for interpolation
      const minWidth = 850; // Max width for the interpolation logic
      const rangeStart = 0.2; // Start of the interpolation range
      const rangeEnd = 0.75; // End of the interpolation range
      const topStart = 22; // Top value at the start of interpolation (%)
      const topEnd = 55; // Top value at the end of interpolation (%)

      if (window.innerWidth <= minWidth) {
        if (scrollFraction <= rangeStart) {
          return `${topStart}%`; // Return starting value before interpolation
        } else if (scrollFraction > rangeStart && scrollFraction <= rangeEnd) {
          // Interpolate between `topStart` and `topEnd`
          const normalizedFraction =
            (scrollFraction - rangeStart) / (rangeEnd - rangeStart);
          const interpolatedTop =
            topStart + normalizedFraction * (topEnd - topStart);
          return `${interpolatedTop}%`;
        } else {
          return `${topEnd}%`; // Return ending value after interpolation
        }
      } else {
        // Default behavior for larger screens
        return `${topEnd}%`;
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    loadFrames(folder, count) {
      const frames = Array.from({ length: count }, (_, i) =>
        require(`@/static/img/FallingGuy/${folder}/Frame${String(i).padStart(
          2,
          "0"
        )}.png`)
      );
      this.preloadImages(frames);
      return frames;
    },
    preloadImages(images) {
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
        this.preloadedImages.push(img); // Store preloaded image objects (optional)
      });
    },
  },
  mounted() {
    this.jumpFrames = this.loadFrames("Jump", 13);
    this.loopFallFrames = this.loadFrames("LoopFall", 12);
    this.endFrames = this.loadFrames("End", 22);

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>


<style scoped>
.falling-guy {
  position: fixed;
  width: 150px;
  height: 150px;
  top: 45%; /* 30% from the top */
  left: 85%; /* Center horizontally */
  transform: translateX(-50%); /* Center adjustment */
  background-size: cover;
  background-position: center;
  z-index: -10; /* Keeps it behind other content */
  transition: background-image 0.1s ease-in-out; /* Smooth transition */
}

@media screen and (max-width: 1200px) {
  .falling-guy {
    top: 38%; /* 30% from the top */
    left: 85%;
  }
}
@media screen and (max-width: 850px) {
  .falling-guy {
    top: 22%;
    left: 90%;
    width: 80px;
    height: 80px;
  }
}
</style>