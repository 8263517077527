<template>
  <div>
    <!-- <Background /> -->
    <Header />
    <Games />
  </div>
</template>

<script>
import Background from "../components/Background.vue";
import Header from "../components/Header.vue";
import Games from "../components/Games.vue";

export default {
  components: {
    Background,
    Header,
    Games,
    Games,
  },
};
</script>

<style>
</style>