<template>
  <div id="app">
    <Background />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Background from "./components/components/Background.vue";
import Footer from "./components/components/Footer.vue";

export default {
  components: {
    Background,
    Footer,
  },
};
</script>


<style>
html {
  background: #4fb2e9;
  margin: 0;
  padding: 0;
  font-family: "Roboto-Medium", sans-serif;
  height: 100%;
  /* overscroll-behavior: none; */
  overflow-x: hidden;
}

body {
  height: auto !important;
  margin: 0;
  padding: 0;
  height: 100%;
  /* overscroll-behavior: none; */
  overflow-x: hidden;
  overflow-y: hidden;
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url(fonts/Roboto/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
</style>