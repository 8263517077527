var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('FallingGuy',{attrs:{"frameCount":54}}),_c('div',{staticClass:"sun",style:({ backgroundImage: `url(${_vm.sunImage})` })}),_vm._l((_vm.clouds),function(cloud,index){return _c('div',{key:index,staticClass:"cloud",style:({
      top: `${cloud.top}px`,
      left: `${
        cloud.side === 'left'
          ? _vm.currentConfig.leftPercent
          : _vm.currentConfig.rightPercent
      }`,
      backgroundImage: `url(${cloud.image})`,
      width: `${cloud.width}px`,
      height: `${cloud.width}px`,
      transform: `scale(${cloud.scale})`,
    })})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }