<template>
  <div class="header">
    <span
      v-for="(char, index) in characters"
      :key="index"
      :class="{
        letter: char !== '\n' && char !== ' ',
        break: char === '\n',
        space: char === ' ',
      }"
    >
      <template v-if="char === '\n'"><br /></template>
      <template v-else-if="char === ' '">&nbsp;</template>
      <template v-else>{{ char }}</template>
    </span>
  </div>
</template>



<script>
export default {
  data() {
    return {
      text: "Hey!\nI make games.", // Use \n for line breaks
    };
  },
  computed: {
    characters() {
      // Split the text into characters, preserving line breaks as \n
      return this.text.split("");
    },
  },
};
</script>


<style scoped>
.header {
  font-size: 70px;
  width: 80%;
  margin: 0 auto;
  padding-top: 35px;
  text-align: left;
  color: white;
  cursor: default;
}

.letter {
  display: inline-block; /* Allows individual transforms */
  transition: transform 0.1s ease;
}

.letter:hover {
  transform: scale(1.05);
  color: #fffc00;
  z-index:1000;
}

.break {
  display: block; /* Ensures line breaks take effect */
  height: 5px;
}

@media screen and (max-width: 1300px) {
  .header {
    font-size: 75px;
    padding-top: 85px;
    /* padding-bottom: 15%; */
  }
}

@media screen and (max-width: 1100px) {
  .header {
    font-size: 55px;
    padding-top: 55px;
    /* padding-bottom: 15%; */
  }
}

@media screen and (max-width: 650px) {
  .header {
    font-size: 45px;
    padding-top: 55px;
    /* padding-bottom: 15%; */
  }
}
</style>