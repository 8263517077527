<template>
  <div class="footer">
    <div class="goTop" @click="scrollToTop">
      <img src="../../static/img/UI/UpArrow.png" />
    </div>
    <div class="footer-content">
      Feel free to reach out at
      <span
        class="mail"
        @click="copyMail"
        @mouseover="hoverMessage = 'Click to copy'"
        @mouseleave="hoverMessage = ''"
        :title="hoverMessage"
      >
        benjamin.widawski@gmail.com
      </span>
      📧
      <div v-if="copied" class="copy-message">Mail copied to clipboard!</div>
    </div>
    <div style="font-size: 18px">
      Oh, and I also
      <a class="link" href="https://music.benjamin-widawski.com" target="_blank"
        >compose music</a
      >!
    </div>
    <div>Benjamin Widawski - {{ currentYear }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      copied: false, // Tracks whether the mail has been copied
      hoverMessage: "Click to copy", // Tooltip for hover
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    copyMail() {
      const email = "benjamin.widawski@gmail.com";
      navigator.clipboard.writeText(email).then(() => {
        this.copied = true;
        // Reset the message after 2 seconds
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Enables smooth scrolling
      });
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%; /* Full width of the screen */
  height: 400px; /* Fixed height */
  background-size: cover; /* Make sure the image covers the entire footer */
  display: flex; /* Center the content */
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  row-gap: 20px;
}

.footer::before {
  content: ""; /* Creates the pseudo-element */
  position: absolute; /* Positioned relative to .footer */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../static/img/Background/Ground.png") no-repeat center
    center;
  background-size: cover; /* Ensure the background fills the element */
  z-index: -100; /* Push the background behind everything */
  pointer-events: none; /* Prevent interaction with the background */
}

.footer-content {
  font-size: 18px;
  color: black;
  text-align: center;
}

.mail {
  color: #3082af; /* Highlight email */
  font-weight: bold;
  cursor: pointer;
}

.mail:hover {
  text-decoration: underline;
}

.copy-message {
  margin-top: 10px;
  font-size: 14px;
  color: #25ca3b; /* Bright yellow for visibility */
  animation: fadeInOut 2s ease-in-out;
}

/* Animation for the message */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10%,
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.goTop {
  width: 40px;
  height: 40px;
  transition: all ease 0.1s;
}

.goTop:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.goTop img {
  width: 100%;
  height: 100%;
}

.link {
  text-decoration: none;
  color: #e7721b;
}
.link:hover {
  text-decoration: underline;
}
</style>