<template>
  <div class="background">
    <FallingGuy :frameCount="54" />
    <!-- Sun at the top right -->
    <div class="sun" :style="{ backgroundImage: `url(${sunImage})` }"></div>

    <!-- Clouds dynamically added -->
    <div
      v-for="(cloud, index) in clouds"
      :key="index"
      class="cloud"
      :style="{
        top: `${cloud.top}px`,
        left: `${
          cloud.side === 'left'
            ? currentConfig.leftPercent
            : currentConfig.rightPercent
        }`,
        backgroundImage: `url(${cloud.image})`,
        width: `${cloud.width}px`,
        height: `${cloud.width}px`,
        transform: `scale(${cloud.scale})`,
      }"
    ></div>
  </div>
</template>

<script>
import FallingGuy from "../components/FallingGuy.vue";

export default {
  data() {
    return {
      sunImage: require("../../static/img/Background/Sun.png"),
      cloudImages: [], // Array to hold cloud images
      clouds: [], // Array of cloud objects with position, side, size, and randomization

      // Screen size configurations
      cloudConfig: {
        large: {
          firstOffset: 400,
          minOffset: 400,
          maxOffset: 800,
          width: 300,
          offsetXRange: 100, // Random horizontal offset range (+/- this value)
          scaleRange: { min: 0.8, max: 1.2 }, // Random scaling range
          leftPercent: "5%", // Left position for clouds
          rightPercent: "75%", // Right position for clouds
        },
        medium: {
          firstOffset: 500,
          minOffset: 300,
          maxOffset: 1000,
          width: 500,
          offsetXRange: 0,
          scaleRange: { min: 0.8, max: 1.2 },
          leftPercent: "-10%",
          rightPercent: "65%",
        },
        small: {
          firstOffset: 500,
          minOffset: 300,
          maxOffset: 1000,
          width: 200,
          offsetXRange: 0,
          scaleRange: { min: 0.8, max: 1.2 },
          leftPercent: "0%",
          rightPercent: "50%",
        },
      },
      currentConfig: {}, // Active config based on screen size
      previousDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      }, // Track previous dimensions
    };
  },
  mounted() {
    this.loadCloudImages();
    this.updateCloudConfig(); // Determine the config based on screen size
    this.generateClouds();

    // Recalculate clouds and update config on resize
    // window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    loadCloudImages() {
      const cloudCount = 2; // Total number of cloud images
      this.cloudImages = Array.from({ length: cloudCount }, (_, i) =>
        require(`../../static/img/Background/Clouds/${i + 1}.png`)
      );
    },
    updateCloudConfig() {
      const screenWidth = window.innerWidth;

      if (screenWidth > 1200) {
        this.currentConfig = this.cloudConfig.large;
      } else if (screenWidth > 600) {
        this.currentConfig = this.cloudConfig.medium;
      } else {
        this.currentConfig = this.cloudConfig.small;
      }
    },
    generateClouds() {
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const clouds = [];
      let currentTop = this.currentConfig.firstOffset;

      let side = "left";
      while (currentTop < totalHeight) {
        const randomImage =
          this.cloudImages[Math.floor(Math.random() * this.cloudImages.length)];

        // Generate random values for offsetX and scale
        const offsetX =
          (Math.random() * 2 - 1) * this.currentConfig.offsetXRange; // Random between -offsetXRange and +offsetXRange
        const scale =
          this.currentConfig.scaleRange.min +
          Math.random() *
            (this.currentConfig.scaleRange.max -
              this.currentConfig.scaleRange.min); // Random scale between min and max

        clouds.push({
          top: currentTop,
          side: side,
          image: randomImage,
          width: this.currentConfig.width, // Use width from config
          offsetX: offsetX, // Horizontal offset
          scale: scale, // Scale value
        });

        // Alternate side for the next cloud
        side = side === "left" ? "right" : "left";

        // Increment the top position by a random offset within the range
        currentTop +=
          this.currentConfig.minOffset +
          Math.random() *
            (this.currentConfig.maxOffset - this.currentConfig.minOffset);
      }

      this.clouds = clouds;
    },
    handleResize() {
      const currentWidth = window.innerWidth;
      const currentHeight = window.innerHeight;

      const widthChange =
        Math.abs(currentWidth - this.previousDimensions.width) /
        this.previousDimensions.width;
      const heightChange =
        Math.abs(currentHeight - this.previousDimensions.height) /
        this.previousDimensions.height;

      // Apply updates only if the change exceeds 30%
      if (widthChange > 0.3 || heightChange > 0.3) {
        this.previousDimensions = {
          width: currentWidth,
          height: currentHeight,
        };
        this.updateCloudConfig();
        this.generateClouds();
      }
    },
  },
  components: {
    FallingGuy,
  },
};
</script>

<style>
.background {
  position: relative;
  width: 100%;
  height: 100%;
}

.sun {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -10;
}

.cloud {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -11;
  transition: transform 0.2s ease; /* Optional: Smooth scaling animations */
}
</style>
