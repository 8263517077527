<template>
  <div class="game-list">
    <div
      v-for="(game, index) in games"
      :key="index"
      class="game-item"
      :class="game.url ? 'game-item-clickable' : ''"
      :style="{ backgroundImage: `url(${borderImage})` }"
      @click="openUrl(game)"
    >
      <!-- Game image -->
      <img
        v-if="game.image"
        :src="game.image"
        alt="Game Image"
        class="game-image"
      />
      <div v-else class="video-embed-container">
        <iframe
          :src="game.videoId"
          frameborder="0"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          scrolling="no"
          class="game-video"
        ></iframe>
      </div>

      <!-- Game description -->
      <p class="game-description">{{ game.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      borderImage: require("../../static/img/Game/Border.png"),
      games: [
        {
          image: require("../../static/img/Games/LoLdle.png"),
          description:
            "Daily guess League of Legends champions with clues for each try, a quote and more!",
          url: "https://loldle.net",
        },
        {
          image: require("../../static/img/Games/LifeOfASkeleton.png"),
          description:
            "What does a skeleton do when the summoner doesn’t summon him?",
          url: "https://ldjam.com/events/ludum-dare/55/like-of-a-skeleton",
        },
        {
          image: require("../../static/img/Games/HowManyGiraffes.png"),
          description: "How good are you with size estimations?",
          url: "https://itch.io/jam/gmtk-2024/rate/2910631",
        },
        {
          image: require("../../static/img/Games/EmojiKitchen.png"),
          description: "Which emojis are combined?",
          url: "https://emoji-kitchen.com/",
        },
        {
          image: require("../../static/img/Games/HOrNot.gif"),
          description:
            "A reminder that anyone can be fooled by the most hateful people if they are eloquent enough.",
          url: "https://hitler-or-not.com/",
        },
        {
          image: require("../../static/img/Games/FlappyPipes.png"),
          description: "Flappy Bird reversed: the pipes are jumping!",
          url: "https://itch.io/jam/gmtk-2023/rate/2156848",
        },
        {
          image: require("../../static/img/Games/Pokedle.png"),
          description:
            "Daily guess Pokémon with a blurry card, a silhouette and more!",
          url: "https://pokedle.net",
        },
        {
          image: require("../../static/img/Games/Smashdle.png"),
          description:
            "Daily guess Smash Bros. characters with Kirby post-diner, a Final Smash and more!",
          url: "https://smashdle.net",
        },
        {
          image: require("../../static/img/Games/OnePiecedle.png"),
          description:
            "Daily guess One Piece characters with laughs audios, a bounty poster and more!",
          url: "https://onepiecedle.net",
        },
        {
          image: require("../../static/img/Games/Narutodle.png"),
          description:
            "Daily guess Naruto characters with jutsu GIFs, a cropped eye and more!",
          url: "https://narutodle.net",
        },
        {
          image: require("../../static/img/Games/Dotadle.png"),
          description:
            "Daily guess DOTA 2 Heroes with a quote, a loading screen and more!",
          url: "https://dotadle.net",
        },
        {
          videoId: "https://www.youtube.com/embed/cVXAKeXYFg8",
          description: "Shapeshift to anything, but get in shape!",
        },
        {
          videoId: "https://www.youtube.com/embed/F-dXiP0vBoE",
          description: "How about a medley of mobile games?",
        },
      ],
    };
  },
  methods: {
    openUrl(game) {
      if (game.url) window.open(game.url, "_blank").focus();
    },
  },
};
</script>

<style>
.game-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  row-gap: 100px;
}

@media screen and (max-width: 850px) {
  .game-list {
    row-gap: 50px;
  }
}

.game-item {
  width: 40%;
  max-width: 1000px;
  aspect-ratio: 4 / 3; /* Maintain the rectangle shape */
  margin: 20px 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  transition: transform ease 0.1s;
  z-index: 1;
}

@media screen and (max-width: 1300px) {
  .game-item {
    width: 50%;
    max-width: 1200px;
  }
}

@media screen and (max-width: 850px) {
  .game-item {
    width: 100%;
    /* max-width: 1000px; */
  }
}

.game-item:nth-child(odd):hover {
  transform: rotate(3deg) scale(1.02);
}

.game-item:nth-child(even):hover {
  transform: rotate(-3deg) scale(1.02);
}

.game-item-clickable {
  cursor: pointer;
}

.game-image {
  width: 80%; /* Make the image fit within the box */
  max-height: 55%; /* Prevent it from taking too much vertical space */
  object-fit: contain;
  margin-bottom: 15px;
}

.game-video {
  width: 100%; /* Make the image fit within the box */
  max-height: 100%; /* Prevent it from taking too much vertical space */
  object-fit: contain;
}

.game-description {
  font-size: 25px;
  line-height: 1.5;
  color: #333;
  word-wrap: break-word;
  padding: 0 12%;
  margin: 0 !important;
}

@media screen and (max-width: 1300px) {
  .game-description {
    font-size: 25px;
  }
}

@media screen and (max-width: 850px) {
  .game-description {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  .game-description {
    font-size: 15px;
  }
}

.video-embed-container {
  position: relative;
  padding-bottom: 40.5%;
  height: 0;
  overflow: hidden;
  max-width: 430px;
  width: 80%;
  height: auto;
  margin-bottom: 15px;
}

@media screen and (max-width: 1300px) {
  .video-embed-container {
    max-width: 630px;
  }
}

@media screen and (max-width: 600px) {
  .video-embed-container {
    max-width: 630px;
  }
}

.video-embed-container iframe,
.video-embed-container object,
.video-embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
